import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import styled from "styled-components"
import Progress from "./progress"

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 308px;
  height: 280px;
  ${"" /* border-right: 1px black solid;
  border-bottom: 1px black solid; */}

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
    }
    /* &:hover ${Icon} {
      opacity: 0.6;
    } */
  }
`

const Title = styled.div`
  height: 49px;
  color: #060606;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.05em;
  font-weight: 500;
`

const Icon = styled.div`
  position: absolute;
  top: 70px;
  left: 9px;

  transition: 0.1s ease-in-out opacity;
  pointer-events: none;
`

const Name = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 0.05em;
`

const Occupation = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
`

const ProfileImg = styled.img`
  object-fit: cover;
  width: 109px;
  height: 109px;
`

const PreviewContainer = styled.div`
  top: 10px;
  left: 10px;
  position: absolute;
  border-right: 1px black solid;
  border-bottom: 1px black solid;
  width: 100%;
  height: 100%;

  &:hover {
    border-right: 4px black solid;
    border-bottom: 4px black solid;
  }
`

const Preview = ({ node, onClick }) => (
  <Container>
    <PreviewContainer onClick={onClick} />
    <Title>{node.Title}</Title>
    <ProfileImg src={node.imageUrl} />
    <Name>{node.name}</Name>
    <Occupation>{node.Occupation}</Occupation>
  </Container>
)

export default Preview
