import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LinkBar from "../components/linkBar"
import { navigate } from "@reach/router"
import PreviewImg from "../components/previewImg"
import Footer from "../components/footer"
import Modal from "../components/modal"
import { useSiteMetadataSizes } from "../hooks/useSiteMetadata"

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 25px;
`

const Previews = styled.section`
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 28px;
  row-gap: 30px;
  width: 980px;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 40px 0;
  margin-bottom: 130px;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) {
    width: auto;
  }
  @media (max-width: ${({ sizes }) => sizes.laptop}px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${({ sizes }) => sizes.tablet}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: ${({ sizes }) => sizes.mobile}px) {
  }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) {
  }
`

const Title = styled.h1`
  margin-top: 80px;
  @media (max-width: 840px) {
    margin-top: 24px;
  }
`

const SubheaderLine = styled.div`
  margin-bottom: 40px;
  height: 20px;
  width: 100%;
  max-width: 336px;
  border-bottom: 2px solid #ff0606;

  @media (max-width: 840px) {
    margin-bottom: 30px;
  }
`

const BreadcrumbContainer = styled.div`
  margin-top: 32px;
  display: flex;
  width: 100%;
  max-width: 980px;
  font-size: 16px;
  color: #000000;

  a {
    color: #000000;
    text-decoration: none;
    opacity: 40%;
  }

  .arrow {
    opacity: 40%;
    margin: 0 6px;
  }
`

export default ({ pageContext }) => {
  const originals = pageContext.originals
  const sizes = useSiteMetadataSizes()

  return (
    <Layout>
      <SEO title="スピーカーを見る | VOOX" />
      <LinkBar />
      <Main>
        <BreadcrumbContainer>
          <Link to="/" replace>
            TOP
          </Link>
          <div className="arrow">{" > "}</div>
          スピーカーを見る
        </BreadcrumbContainer>
        <Title>スピーカー</Title>
        <SubheaderLine />
        <Previews sizes={sizes}>
          {originals.map(node => (
            <PreviewImg
              onClick={() => {
                navigate(`/episodes/${node.id}/`)
              }}
              key={node.id}
              node={{
                name: node.author,
                Occupation: node.jobTitle,
                imageUrl: node.imageSmallUrl,
              }}
              progress={false}
              isPlaying={false}
            />
          ))}
        </Previews>
      </Main>
      <Footer sizes={sizes} />
    </Layout>
  )
}
